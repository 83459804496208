import * as grid from "../styles/grid.module.scss";
import * as styles from '../styles/gavekort.module.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import ContentMargins from "../components/layout/ContentMargins";
import InputLabel from "@material-ui/core/InputLabel";
import Layout from "../components/layout/layout";
import MenuItem from "@material-ui/core/MenuItem";
import PageTitle from "../components/contentHeadings/PageTitle";
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Select from "@material-ui/core/Select";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import Grid from "@material-ui/core/Grid";
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";
import {Hidden} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


// import firebase from "firebase/app";
// import "firebase/functions";
// const app = firebase.initializeApp({
//     apiKey: "AIzaSyAX67zJ0Nxka8oZ9sQ1n2NY_WMcVSvNf9A",
//     authDomain: "benediktevippeextensions-c5f6b.firebaseapp.com",
//     databaseURL: "https://benediktevippeextensions-c5f6b.firebaseio.com",
//     projectId: "benediktevippeextensions-c5f6b",
//     storageBucket: "benediktevippeextensions-c5f6b.appspot.com",
//     messagingSenderId: "203544960780",
//     appId: "1:203544960780:web:2ac63c0cdc4d50d7c7f329",
//     measurementId: "G-1ZGS8QRY17"
// });

// const functions = app.functions();

const GavekortPage = () => {
    const [selectedVariant, setSelectedVariant] = useState({index: 0, price: 1390});
    const pendingPurchase = useRef(false);
    const [displayLoading, setDisplayLoading] = useState(false);

    // Async-ly load firebase and wake up the
    // create stripe session function
    const fn = useRef(null);
    useEffect(() => {
        (async () => {
            if (!window.firebase_functions){
                const p1 = import("firebase/app");
                const p2 = import("firebase/functions");

                fn.current = await Promise.all([p1, p2]).then(([firebase, f]) => {
                    let app;
                    const apps = firebase.default.apps;
                    const existingApp = apps.find(app => app['name_'] === "bv-gavekort");  // See name config below

                    if (existingApp){
                        app = existingApp;
                    } else {
                        app = firebase.default.initializeApp({
                            apiKey: "AIzaSyAX67zJ0Nxka8oZ9sQ1n2NY_WMcVSvNf9A",
                            authDomain: "benediktevippeextensions-c5f6b.firebaseapp.com",
                            databaseURL: "https://benediktevippeextensions-c5f6b.firebaseio.com",
                            projectId: "benediktevippeextensions-c5f6b",
                            storageBucket: "benediktevippeextensions-c5f6b.appspot.com",
                            messagingSenderId: "203544960780",
                            appId: "1:203544960780:web:2ac63c0cdc4d50d7c7f329",
                            measurementId: "G-1ZGS8QRY17"
                        }, "bv-gavekort");
                    }

                    return app.functions("europe-north1");
                });

                window.firebase_functions = fn.current;
            } else {
                fn.current = window.firebase_functions;
            }

            const functions = await fn.current;

            // This call does nothing but wake up
            // the function to spin up a new instance
            const createCheckoutSession = functions.httpsCallable('createCheckoutSession');
            await createCheckoutSession({
                wake: true,
                live: (process.env.DEV_MODE === "FALSE")
            });
        })();
    }, []);

    const handlePurchase = useCallback(async (productId, price) => {
        if (pendingPurchase.current){return;}
        pendingPurchase.current = true;
        setDisplayLoading(productId);

        const sessionPrice = (parseInt(price, 10) || selectedVariant.price) * 100;

        const functions = await fn.current;
        const sessionRequest = await functions.httpsCallable('createCheckoutSession')({
            productId: productId,
            price: sessionPrice,
            live: (process.env.DEV_MODE === "FALSE"),  // if false the cloud function will use the test key to create a session
        });

        const stripeService = window.Stripe(process.env.STRIPE_KEY);
        const sessionId = sessionRequest.data;

        stripeService.redirectToCheckout({
            sessionId: sessionId
        }).then(res => {
            console.error("Failed to redirect to stripe...", res);
        });

        pendingPurchase.current = false;
        setDisplayLoading(null);
    }, [selectedVariant]);

    const handleSelectVariant = useCallback(event => {
        const newVal = event.target.value;
        const priceByVariantIndex = [1590, 1590, 1790, 790, 990];

        setSelectedVariant({index: newVal, price: priceByVariantIndex[newVal]});
    }, []);

    const products = useMemo(() => {
        const data = [
            {
                name: 'Gavekort: Vippeløft',
                image: <StaticImage
                    layout={"constrained"}
                    src={"../images/gavekort/vl_cropped.png"}
                    alt={"Gavekort for Vippeløft"}
                    width={456}
                    sizes={"(min-width: 960px): 213px, calc(50vw - 24px)"}
                    outputPixelDensities={outputPixelDensities}
                />,
                productId: 'vl',
                price: "650"
            },
            {
                name: 'Gavekort: Vippeløft med farge',
                image: <StaticImage
                    src={"../images/gavekort/vlf_cropped.png"}
                    alt={"Gavekort for Vippeløft med farge"}
                    width={456}
                    sizes={"(min-width: 960px): 213px, calc(50vw - 24px)"}
                    outputPixelDensities={outputPixelDensities}
                />,
                productId: 'vlf',
                price: "890"
            },
            {
                name: 'Gavekort: Vippeløft med farge for to',
                image: <StaticImage
                    src={"../images/gavekort/vl2_cropped.png"}
                    alt={"Gavekort for Vippeløft med farge for to"}
                    width={456}
                    sizes={"(min-width: 960px): 213px, calc(50vw - 24px)"}
                    outputPixelDensities={outputPixelDensities}
                />,
                productId: 'vl2',
                price: "1390"
            },
            {
                name: 'Gavekort på extensions',
                image: <StaticImage
                    src={"../images/gavekort/belop_cropped.png"}
                    alt={"Gavekort på beløp"}
                    width={456}
                    sizes={"(min-width: 960px): 213px, calc(50vw - 24px)"}
                    outputPixelDensities={outputPixelDensities}
                />,
                productId: 'belop',
                variants: [
                    {label: "Nytt sett klassisk", price: "1590"},
                    {label: "Nytt sett naturlig volum", price: "1590"},
                    {label: "Nytt sett dramatisk volum", price: "1790"},
                    {label: "Påfyll, 60 min", price: "790"},
                    {label: "Påfyll, 90 min", price: "990"}
                ]
            }
        ];

        return data.map(product => (
            <div className={grid.xs6Md3P12} key={product.productId}>
                {/*<Img fluid={{*/}
                {/*    ...product.image,*/}
                {/*    sizes: "(min-width: 960px): 207px, calc(50vw - 24px)"*/}
                {/*}}/>*/}
                { product.image }
                <p className={styles.productName}>{ product.name }</p>
                {
                    !product.hasOwnProperty('variants') ? null : (
                        <div className={styles.variantSelect}>
                            <InputLabel id={`variant_${product.productId}_label`}>Velg beløp</InputLabel>
                            <Select
                                labelId={`variant_${product.productId}_label`}
                                value={selectedVariant.index}
                                onChange={handleSelectVariant}
                                className={styles.variantInput}
                            >
                                {
                                    product.variants.map((variant, index) => (
                                        <MenuItem
                                            value={index}
                                            key={index.toString()}
                                        >
                                            { variant.label + ` (${variant.price},-)` }
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    )

                }
                <p className={styles.productPrice}>{ product.hasOwnProperty('price') ? product.price + ",-" : product.variants[selectedVariant.index].price + ",-" }</p>
                <button className={styles.buyButton + (displayLoading === product.productId ? ' ' + styles.buttonProgressColor : '')} onClick={() => {handlePurchase(product.productId, product.price || null)}}>
                    { displayLoading === product.productId ? <CircularProgress className={styles.buttonProgress} size={"1.3rem"} /> : 'Kjøp' }
                </button>
            </div>
        ));
    }, [handlePurchase, displayLoading, selectedVariant, handleSelectVariant]);

    const news = useMemo(() => (
        <Typography><span style={{color: 'green', fontWeight: 'bold'}}>Nyhet!</span> Gavekortene våre leveres nå i en lekker konvolutt med vokssegl. Vi sender det ferdig innpakkede gavekortet i en ytre boblekonvolutt slik at den kommer trygt frem til deg.</Typography>
    ), []);

    const info = useMemo(() => (
        <React.Fragment>
            <Typography className={styles.text}>
                Kjøper du et gavekort på nettsiden får du både en digital utgave av gavekortet på e-post, og en fysisk utgave i posten, uten ekstra kostnad!
                Vi sender normalt ut den fysiske utgaven av gavekortet samme dag, eller dagen etter. Den digitale utgaven kommer på e-post umiddelbart etter kjøpet.
            </Typography>
            <Typography className={styles.text}>
                Vi har egne design for gavekort til vippeløft. Gavekort til vippeextensions ligger under “valgfritt beløp”, se nedtrekksmenyen nedenfor.
            </Typography>
            <Typography className={styles.text}>
                Mottakeren kan velge en annen behandling enn den som følger av kortet (og evt. betale mellomlegget).
            </Typography>
        </React.Fragment>
    ), []);
    
    return(
        <Layout>
            <Seo
                title={"Kjøp Gavekort"}
                extraTags={<script src={'https://js.stripe.com/v3/'} />}
            />
            <ContentMargins topMargin>
                <PageTitle>Kjøp Gavekort</PageTitle>
                <SubHeading>Gled en du er glad i med flotte vipper!</SubHeading>
                <Hidden smUp>{ news }</Hidden>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Hidden xsDown>
                            { news }
                            { info }
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={styles.videoContainer}>
                            <video controls playsInline autoPlay muted width={"1080"} src={"/giftcard_video/web1080.mp4"} className={styles.video}>
                                <source src={"/giftcard_video/web1080.webm"} type={"video/webm"}/>
                                <source src={"/giftcard_video/web1080.mp4"} type={"video/mp4"}/>
                            </video>
                        </div>
                    </Grid>
                </Grid>
                <Hidden smUp>
                    {info}
                </Hidden>
                <div className={grid.container}>
                    {products}
                </div>
            </ContentMargins>
        </Layout>
    )
};

export default GavekortPage;