// extracted by mini-css-extract-plugin
export var buttonLink = "gavekort-module--button-link--s11+0";
export var buttonProgress = "gavekort-module--button-progress--9aGT8";
export var buttonProgressColor = "gavekort-module--button-progress-color--jJ4Uv";
export var buyButton = "gavekort-module--buy-button--cQEVx";
export var productName = "gavekort-module--product-name--oo8s3";
export var productPrice = "gavekort-module--product-price--UyP43";
export var text = "gavekort-module--text--XuTet";
export var variantInput = "gavekort-module--variant-input--PN51D";
export var variantSelect = "gavekort-module--variant-select--v1+my";
export var video = "gavekort-module--video--EvQas";
export var videoContainer = "gavekort-module--video-container--+4W9w";